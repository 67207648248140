<template>
  <div class="about">
    <!-- <v-card class="ma-3">
      <v-card-title>Hranice</v-card-title>
      <v-card-text></v-card-text>
      <v-card-actions>
        <v-card-actions>
          <v-btn
            small
            color="success"
          >Nová</v-btn>

          <v-btn
            small
            color="primary"
          >Uložiť</v-btn>
        </v-card-actions>
      </v-card-actions>
    </v-card> -->

    <v-card class="ma-3">
      <v-card-title>Notifikačné emailové adresy</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Email
                </th>
                <th class="text-left">
                  Kontakty
                </th>
                <th class="text-left">
                  Hlásenia
                </th>
                <th class="text-left">
                  Chyby
                </th>
                <th class="text-left">
                  Akcie
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in emails"
                :key="item.id"
              >
                <td>
                  <v-text-field v-model="item.email"></v-text-field>
                </td>
                <td>
                  <v-checkbox v-model="item.contacts"></v-checkbox>
                </td>

                <td>
                  <v-checkbox v-model="item.reports"></v-checkbox>
                </td>

                <td>
                  <v-checkbox v-model="item.errors"></v-checkbox>
                </td>

                <td>
                  <v-btn
                    small
                    color="error"
                    @click="deleteItem(item)"
                  >Zmazať</v-btn>
                  <v-btn
                    class="ml-9"
                    small
                    color="primary"
                    @click="saveItem(item)"
                  >Uložiť</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-btn
          small
          color="success"
          @click="addItemEmails"
        >Nová</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import session from '/src/http/session';

export default {
  data() {
    return {
      emails: []
    }
  },
  mounted() {
    session.get('/api_admin/notification_emails/')
      .then(response => (this.emails = response.data));
  },

  methods: {
    addItemEmails: function () {
      this.emails.push({ email: "", contacts: false, reports: false, errors: false });
    },
    deleteItem(item) {
      session.delete('/api_admin/notification_emails/' + item.id).finally(() =>
        session.get('/api_admin/notification_emails/')
          .then(response => (this.emails = response.data))
      )
    },
    saveItem(item) {
      if (item.id) {
        session.put('/api_admin/notification_emails/' + item.id + "/", item).finally(() =>
          session.get('/api_admin/notification_emails/')
            .then(response => (this.emails = response.data))
        );
      } else {
        session.post('/api_admin/notification_emails/', item).finally(() =>
          session.get('/api_admin/notification_emails/')
            .then(response => (this.emails = response.data))
        );
      }
    }
  }
}
</script>
