var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-3"},[_c('v-card-title',[_vm._v("Smogové situácie")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',[_c('v-card-title',[_vm._v("Text")]),_c('v-card-text',[_vm._v(_vm._s(item.text))])],1)],1)]}},{key:"item.station",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStationName(item.station))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-btn',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.cancelSmogSituation(item)}}},[_vm._v("Zrušiť")]):_vm._e()]}}])})],1),_c('v-card-actions',[_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-3",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("Nová smogová situácia")])]}},{key:"default",fn:function(){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Nová smogová situácia")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.stations,"item-text":"text","item-value":"value","label":"Stanica"},model:{value:(_vm.newItem.station),callback:function ($$v) {_vm.$set(_vm.newItem, "station", $$v)},expression:"newItem.station"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"items":['PM10', 'PM2.5', 'NO2', 'O3', 'SO2'],"label":"Látka"},model:{value:(_vm.newItem.pollutant),callback:function ($$v) {_vm.$set(_vm.newItem, "pollutant", $$v)},expression:"newItem.pollutant"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"label":"Text","rows":"1","auto-grow":""},model:{value:(_vm.newItem.text),callback:function ($$v) {_vm.$set(_vm.newItem, "text", $$v)},expression:"newItem.text"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.createSmogSituation(_vm.newItem)}}},[_vm._v("Vytvoriť")])],1)],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }