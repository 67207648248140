import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Stations from '../views/Stations.vue'
import Reports from '../views/Reports.vue'
import Contacts from '../views/Contacts.vue'
import Settings from '../views/Settings.vue'
import Login from '../views/Login.vue'
import SmogSituations from '../views/SmogSituations.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/stations',
    name: 'Stations',
    component: Stations
  },
  {
    path: '/smog_situations',
    name: 'Smog Situations',
    component: SmogSituations
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
