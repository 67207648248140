<template>
  <div class="login">

    <v-card class="ma-3 justify-center">
      <v-card-title>Prihlásenie</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="username"
              placeholder="Používateľské meno"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="password"
              placeholder="Používateľské heslo"
              type="password"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          small
          color="primary"
          @click="login"
        >Prihlásiť sa</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import session from '/src/http/session';

export default {
  data() {
    return {
      username: '',
      password: ''
    }
  },
  mounted() {
  },

  methods: {
    login: function () {
      session.defaults.auth = {
        username: this.username,
        password: this.password
      }

      session
        .get('api_admin/notification_emails/')
        .then(() => {
          this.$router.push('/home');
        }
        ).catch(
          () => {
            console.log("nespravne meno/heslo");
          }
        )
    }
  }
}
</script>
