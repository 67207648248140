<template>
  <div>
    <v-card class="ma-3">
      <v-card-title>Kontakty</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="id"
          show-expand
          class="elevation-1"
          :expanded.sync="expanded"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-card>
                <v-card-title>Správa</v-card-title>
                <v-card-text>{{ item.text }}</v-card-text>
              </v-card>
            </td>
          </template>

          <template v-slot:item.active="{ item }">
            <v-simple-checkbox
              v-model="item.active"
              @click="changeActive(item)"
            ></v-simple-checkbox>
          </template>

        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <!-- <v-btn
          small
          color="primary"
        >Uložiť</v-btn> -->
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import session from '/src/http/session';

export default {

  data() {
    return {
      items: [],
      expanded: [],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Vytvorený',
          align: 'start',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'Meno',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Email',
          align: 'start',
          sortable: true,
          value: 'email',
        },
        {
          text: 'Telefón',
          align: 'start',
          sortable: true,
          value: 'phone',
        },
        {
          text: 'Región',
          align: 'start',
          sortable: true,
          value: 'region',
        },
        {
          text: 'Obec',
          align: 'start',
          sortable: true,
          value: 'obec',
        },
        {
          text: 'Kategória',
          align: 'start',
          sortable: true,
          value: 'kategoria',
        },
        {
          text: 'Aktívny',
          align: 'start',
          sortable: true,
          value: 'active',
        },
      ]
    }
  },
  mounted() {
    session.get('/api_admin/contacts/').then(response => (this.items = response.data));
  },

  methods: {
    changeActive(item) {
      session.patch(`/api_admin/contacts/${item.id}/`, { "active": item.active }).then(() => {
        session.get('/api_admin/contacts/').then(response => (this.items = response.data)
        );
      }
      );
    }
  }

}
</script>
