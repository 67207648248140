<template>
  <div>
    <v-card class="ma-3">
      <v-card-title>
        Stanice
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Vyhľadávanie"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          :item-class="itemRowBackground"
          item-key="id"
          class="elevation-1"
        >

          <template v-slot:item.action="{ item }">
            <v-btn
              small
              color="primary"
              :href="getExportUrl(item)"
            >Export do csv</v-btn>
          </template>

          <template v-slot:item.hidden="{ item }">
            <v-simple-checkbox v-model="item.hidden" disabled></v-simple-checkbox>
          </template>

        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <!-- <v-btn
          small
          color="primary"
        >Uložiť</v-btn> -->
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import session from '/src/http/session';

export default {
  components: {
  },

  data() {
    return {
      search: "",

      items: [],

      items_type_area: ['mestská', 'predmestská', 'vidiecka'],
      items_type_station: ['pozaďová', 'dopravná', 'priemyselná'],

      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'ID SHMU',
          align: 'start',
          sortable: true,
          value: 'id_shmu',
        },
        {
          text: 'Názov',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Posledná aktualizácia',
          align: 'start',
          sortable: true,
          value: 'latest_quality.datetime',
        },
        {
          text: 'Skrytá',
          align: 'start',
          sortable: true,
          value: 'hidden',
        },

        {
          text: 'Export',
          align: 'start',
          sortable: true,
          value: 'action',
        },

      ]
    }
  },
  mounted() {
    session.get('/api_admin/stations/')
      .then(response => (this.items = response.data));
  },

  methods: {
    itemRowBackground: function (item) {
      return item.hidden ? 'red' : ''
    },
    getExportUrl(item) {
      return `${session.defaults.baseURL}api_admin/export/${item.id}`;
    }
  },
}

</script>
