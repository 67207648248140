<template>
  <div>
    <v-card class="ma-3">
      <v-card-title>
        Stanice
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Vyhľadávanie"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          item-key="id"
          show-expand
          class="elevation-1"
          :expanded.sync="expanded"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-card>
                <v-card-title>Mapa</v-card-title>
                <v-card-text>
                  <div style="height: 500px; width: 100%">
                    <l-map
                      :zoom="12"
                      :center="getLatLng(item.gps_lat, item.gps_lon)"
                      style="height: 80%"
                    >
                      <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                      <l-marker :lat-lng="getLatLng(item.gps_lat, item.gps_lon)">
                      </l-marker>
                    </l-map>
                  </div>
                </v-card-text>
              </v-card>
            </td>
          </template>

          <template v-slot:item.mobile="{ item }">
            <v-simple-checkbox v-model="item.mobile"></v-simple-checkbox>
          </template>

          <template v-slot:item.hidden="{ item }">
            <v-simple-checkbox v-model="item.hidden"></v-simple-checkbox>
          </template>

          <template v-slot:item.type_area="{ item }">
            <v-select
              v-model="item.type_area"
              :items="items_type_area"
            ></v-select>
          </template>

          <template v-slot:item.type_station="{ item }">
            <v-select
              v-model="item.type_station"
              :items="items_type_station"
            ></v-select>
          </template>

          <template v-slot:item.admin_text="{ item }">
            <v-text-field v-model="item.admin_text"></v-text-field>
          </template>

          <template v-slot:item.action="{ item }">
            <v-btn
              small
              color="primary"
              @click="saveItem(item)"
            >Uložiť</v-btn>
          </template>

        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <!-- <v-btn
          small
          color="primary"
        >Uložiť</v-btn> -->
      </v-card-actions>
    </v-card>

    <v-card>
      <v-card-title>Kompletná mapa</v-card-title>
      <v-card-text>
        <div style="height: 650px; width: 100%">
          <l-map
            :zoom="8"
            :center="getLatLng(48.736279, 19.146193)"
            style="height: 100%"
          >
            <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <l-marker
              v-for="item in items"
              v-bind:key="item.id"
              :lat-lng="getLatLng(item.gps_lat, item.gps_lon)"
              @click="expanded.push(item)"
            >
              <l-tooltip :options="{ permanent: false, interactive: true }">
                <div>
                  ID {{item.id}}: {{ item.name }}
                </div>
              </l-tooltip>
            </l-marker>
          </l-map>
        </div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";

import session from '/src/http/session';

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip
  },

  data() {
    return {
      search: "",

      items: [],
      expanded: [],
      items_type_area: ['mestská', 'predmestská', 'vidiecka'],
      items_type_station: ['pozaďová', 'dopravná', 'priemyselná'],

      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'ID SHMU',
          align: 'start',
          sortable: true,
          value: 'id_shmu',
        },
        {
          text: 'Názov',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'GPS Lat',
          align: 'start',
          sortable: true,
          value: 'gps_lat',
        },
        {
          text: 'GPS Lon',
          align: 'start',
          sortable: true,
          value: 'gps_lon',
        },
        {
          text: 'Mobilná',
          align: 'start',
          sortable: true,
          value: 'mobile',
        },
        {
          text: 'Skrytá',
          align: 'start',
          sortable: true,
          value: 'hidden',
        },

        {
          text: 'Typ oblasti',
          align: 'start',
          sortable: true,
          value: 'type_area',
        },

        {
          text: 'Typ stanice',
          align: 'start',
          sortable: true,
          value: 'type_station',
        },

        {
          text: 'Text',
          align: 'start',
          sortable: true,
          value: 'admin_text',
        },

        {
          text: '',
          align: 'start',
          sortable: true,
          value: 'action',
        },

      ]
    }
  },
  mounted() {
    session.get('/api_admin/stations/')
      .then(response => (this.items = response.data));
  },

  methods: {
    getLatLng(lat, lon) {
      return latLng(lat, lon);
    },
    saveItem(item) {
      session.put(`/api_admin/stations/${item.id}/`, item);
    },
  }

}
</script>
