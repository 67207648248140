<template>
  <div>
    <v-card class="ma-3">
      <v-card-title>Hlásenia</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="id"
          show-expand
          class="elevation-1"
          :expanded.sync="expanded"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-card>
                <v-card-title>Správa</v-card-title>
                <v-card-text>{{ item.text }}</v-card-text>
                <v-card-text>
                  <v-img :src="item.photo"></v-img>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-title>Mapa</v-card-title>
                <v-card-text>
                  <div style="height: 500px; width: 100%">
                    <l-map
                      :zoom="12"
                      :center="getLatLng(item.gps_lat, item.gps_lon)"
                      style="height: 80%"
                    >
                      <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                      <l-marker :lat-lng="getLatLng(item.gps_lat, item.gps_lon)">
                        <l-tooltip :options="{ permanent: true, interactive: true }">
                          <div>
                            Hlásenie
                          </div>
                        </l-tooltip>
                      </l-marker>
                    </l-map>
                  </div>
                </v-card-text>
              </v-card>
            </td>
          </template>

          <template v-slot:item.active="{ item }">
            <v-simple-checkbox v-model="item.active" @click="changeActive(item)"></v-simple-checkbox>
          </template>

        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <!-- <v-btn
          small
          color="primary"
        >Uložiť</v-btn> -->
      </v-card-actions>
    </v-card>

    <v-card>
      <v-card-title>Kompletná mapa</v-card-title>
      <v-card-text>
        <div style="height: 650px; width: 100%">
          <l-map
            :zoom="8"
            :center="getLatLng(48.736279, 19.146193)"
            style="height: 100%"
          >
            <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <l-marker
              v-for="item in items"
              v-bind:key="item.id"
              :lat-lng="getLatLng(item.gps_lat, item.gps_lon)"
              @click="expanded.push(item)"
            >
              <l-tooltip :options="{ permanent: false, interactive: true }">
                <div>
                  {{ item.text }}
                </div>
              </l-tooltip>
            </l-marker>
          </l-map>
        </div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";
import session from '/src/http/session';

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip
  },

  data() {
    return {
      items: [],
      expanded: [],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Vytvorený',
          align: 'start',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'Meno',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Email',
          align: 'start',
          sortable: true,
          value: 'email',
        },

        {
          text: 'Telefón',
          align: 'start',
          sortable: true,
          value: 'phone',
        },

        {
          text: 'GPS Lat',
          align: 'start',
          sortable: true,
          value: 'gps_lat',
        },

        {
          text: 'GPS Lon',
          align: 'start',
          sortable: true,
          value: 'gps_lon',
        },
        {
          text: 'Aktívny',
          align: 'start',
          sortable: true,
          value: 'active',
        },
      ]
    }
  },
  mounted() {
    session.get('/api_admin/reports/')
      .then(response => (this.items = response.data));
  },

  methods: {
    getLatLng(lat, lon) {
      return latLng(lat, lon);
    },

    changeActive(item) {

      session.patch(`/api_admin/reports/${item.id}/`, {'active': item.active}).then(() => {
        session.get('/api_admin/reports/').then(response => (this.items = response.data)
        );
      }
      );
    }
  }

}
</script>
