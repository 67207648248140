import axios from 'axios';
import router from '@/router'

const session = axios.create({
  // baseURL: 'http://localhost:8000',
  baseURL: 'https://app.dnesdycham.jur1cek.eu/',
});

session.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401 || error.response.status == 403) {
    router.push('/');
  }

  return Promise.reject(error);
})


export default session;

