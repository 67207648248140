var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-3"},[_c('v-card-title',[_vm._v(" Stanice "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Vyhľadávanie","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"id","show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',[_c('v-card-title',[_vm._v("Mapa")]),_c('v-card-text',[_c('div',{staticStyle:{"height":"500px","width":"100%"}},[_c('l-map',{staticStyle:{"height":"80%"},attrs:{"zoom":12,"center":_vm.getLatLng(item.gps_lat, item.gps_lon)}},[_c('l-tile-layer',{attrs:{"url":"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}}),_c('l-marker',{attrs:{"lat-lng":_vm.getLatLng(item.gps_lat, item.gps_lon)}})],1)],1)])],1)],1)]}},{key:"item.mobile",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{model:{value:(item.mobile),callback:function ($$v) {_vm.$set(item, "mobile", $$v)},expression:"item.mobile"}})]}},{key:"item.hidden",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{model:{value:(item.hidden),callback:function ($$v) {_vm.$set(item, "hidden", $$v)},expression:"item.hidden"}})]}},{key:"item.type_area",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.items_type_area},model:{value:(item.type_area),callback:function ($$v) {_vm.$set(item, "type_area", $$v)},expression:"item.type_area"}})]}},{key:"item.type_station",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.items_type_station},model:{value:(item.type_station),callback:function ($$v) {_vm.$set(item, "type_station", $$v)},expression:"item.type_station"}})]}},{key:"item.admin_text",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{model:{value:(item.admin_text),callback:function ($$v) {_vm.$set(item, "admin_text", $$v)},expression:"item.admin_text"}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.saveItem(item)}}},[_vm._v("Uložiť")])]}}])})],1),_c('v-card-actions')],1),_c('v-card',[_c('v-card-title',[_vm._v("Kompletná mapa")]),_c('v-card-text',[_c('div',{staticStyle:{"height":"650px","width":"100%"}},[_c('l-map',{staticStyle:{"height":"100%"},attrs:{"zoom":8,"center":_vm.getLatLng(48.736279, 19.146193)}},[_c('l-tile-layer',{attrs:{"url":"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}}),_vm._l((_vm.items),function(item){return _c('l-marker',{key:item.id,attrs:{"lat-lng":_vm.getLatLng(item.gps_lat, item.gps_lon)},on:{"click":function($event){return _vm.expanded.push(item)}}},[_c('l-tooltip',{attrs:{"options":{ permanent: false, interactive: true }}},[_c('div',[_vm._v(" ID "+_vm._s(item.id)+": "+_vm._s(item.name)+" ")])])],1)})],2)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }