<template>
  <v-app id="inspire">
    <v-app-bar
      app
      color="white"
      flat
    >
      <v-container class="py-0 fill-height">
        <!-- <v-avatar
          class="mr-10"
          color="grey darken-1"
          size="32"
        ></v-avatar> -->

        <v-btn
          text
          to="/home"
        >
          Home
        </v-btn>
        <v-btn
          text
          to="/stations"
        >
          Stanice
        </v-btn>
        <v-btn
          text
          to="/smog_situations"
        >
          Smogové situácie
        </v-btn>

        <v-btn
          text
          to="/reports"
        >
          Hlásenia
        </v-btn>
        <v-btn
          text
          to="/contacts"
        >
          Kontakty
        </v-btn>

        <v-btn
          text
          to="/settings"
        >
          Nastavenia
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          text
          @click="logout()"
        >
          Odhlásiť sa
        </v-btn>

        <!-- <v-responsive max-width="260">
          <v-text-field
            dense
            flat
            hide-details
            rounded
            solo-inverted
          ></v-text-field>
        </v-responsive> -->
      </v-container>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <!-- <v-col cols="2">
            <v-sheet rounded="lg">
              <v-list color="transparent">
                <v-list-item
                  v-for="n in 5"
                  :key="n"
                  link
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      List Item {{ n }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider class="my-2"></v-divider>

                <v-list-item
                  link
                  color="grey lighten-4"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Refresh
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-col> -->

          <v-col>
            <v-sheet
              min-height="70vh"
              rounded="lg"
            >
              <router-view />
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import session from '/src/http/session';

export default {

  data: () => ({
  }),

  methods: {
    logout() {
      session.defaults.auth = {
        username: "",
        password: ""
      };
      this.$router.push('/home');
    }
  }
}
</script>
