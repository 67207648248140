<template>
  <div>
    <v-card class="ma-3">
      <v-card-title>Smogové situácie</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="id"
          show-expand
          class="elevation-1"
          :expanded.sync="expanded"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-card>
                <v-card-title>Text</v-card-title>
                <v-card-text>{{ item.text }}</v-card-text>
              </v-card>
            </td>
          </template>

          <!-- <template v-slot:item.active="{ item }">
            <v-simple-checkbox v-model="item.active" disabled></v-simple-checkbox>
          </template> -->

          <template v-slot:item.station="{ item }">
            {{ getStationName(item.station) }}
          </template>

          <template v-slot:item.active="{ item }">
            <v-btn
              v-if="item.active"
              small
              color="error"
              @click="cancelSmogSituation(item)"
            >Zrušiť</v-btn>
          </template>

        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-dialog
          transition="dialog-top-transition"
          max-width="600"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-3"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >Nová smogová situácia</v-btn>
          </template>
          <template v-slot:default="">
            <v-card>
              <v-toolbar
                color="primary"
                dark
              >Nová smogová situácia</v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                    >
                      <v-autocomplete
                        v-model="newItem.station"
                        :items="stations"
                        item-text="text"
                        item-value="value"
                        label="Stanica"
                      ></v-autocomplete>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-select
                        v-model="newItem.pollutant"
                        :items="['PM10', 'PM2.5', 'NO2', 'O3', 'SO2']"
                        label="Látka"
                      ></v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-textarea
                        v-model="newItem.text"
                        label="Text"
                        rows="1"
                        auto-grow
                      ></v-textarea>
                    </v-col>

                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  text
                  @click="createSmogSituation(newItem)"
                >Vytvoriť</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>

      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import session from '/src/http/session';

export default {

  data() {
    return {
      items: [],
      expanded: [],
      stations: [],
      stationsDict: {},
      newItem: {
        station: "",
        text: "",
        pollutant: ""
      },
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Vytvorený',
          align: 'start',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'Stanica',
          align: 'start',
          sortable: true,
          value: 'station',
        },
        {
          text: 'Látka',
          align: 'start',
          sortable: true,
          value: 'pollutant',
        },
        {
          text: 'Aktívny',
          align: 'start',
          sortable: true,
          value: 'active',
        },
      ]
    }
  },
  mounted() {
    session.get('/api_admin/stations/').then(response => {
      for (let station in response.data) {
        this.stations.push({ 'value': response.data[station].id, 'text': response.data[station].name });
        this.stationsDict[response.data[station].id] = response.data[station].name;
      }

      session.get('/api_admin/smog_situations/').then(response => (this.items = response.data));
    }
    )
  },

  methods: {
    createSmogSituation(item) {
      session.post('/api_admin/smog_situations/', item).then(() => {
        this.newItem.station = "";
        this.newItem.pollutant = "";
        this.newItem.text = "";

        session.get('/api_admin/smog_situations/').then(response => (this.items = response.data));
      });
    },

    cancelSmogSituation(item) {
      let answer = confirm("Naozaj chcete zrušiť túto smogovú situáciu?");

      if (answer) {
        item.active = false;
        session.put(`/api_admin/smog_situations/${item.id}/`, item).then(() => {
          session.get('/api_admin/smog_situations/').then(response => (this.items = response.data));
        });
      }
    },

    getStationName(id) {
      return this.stationsDict[id];
    }
  }

}
</script>
